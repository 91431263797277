import * as React from 'react'

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/libs/utils'

import { IconType, IconVariants } from '../Icon/Icon'

export type ButtonVariant =
  | 'link'
  | 'default'
  | 'destructive'
  | 'outline'
  | 'secondary'
  | 'success'
  | 'ghost'
  | null
  | undefined

const buttonVariants = cva(
  'gap-2 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-brand-foreground-2 text-white hover:bg-brand-foreground-2-hover',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-white text-brand-foreground-link hover:bg-neutral-background-5-hover',
        secondary:
          'border border-grey-200 bg-neutral-background-3 text-neutral-foreground-3 hover:bg-neutral-background-3-hover',
        success: 'bg-success text-success-foreground hover:bg-success/90',
        ghost: 'hover:text-primary hover:bg-neutral-background-5-hover',
        link: 'text-brand-foreground-link hover:text-brand-foreground-link-hover underline-offset-4 hover:underline',
        original: 'bg-gray-100 text-blue-500 hover:bg-gray-200'
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-lg px-8',
        icon: 'h-10 w-10',
        circle: 'h-10 w-10 rounded-full',
        badge: 'rounded-full px-5 py-3 font-light'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  /**
   * Whether to render the button as a child of another component.
   */
  asChild?: boolean
  /**
   * An optional icon to display.
   */
  icon?: IconType
  /**
   * The variant of the icon.
   */
  iconVariant?: IconVariants
  /**
   * The custom classname of the icon.
   */
  iconClassName?: string
  /**
   * The alignment of the icon.
   */
  iconAlignment?: 'left' | 'right'
  /**
   * The title of the button.
   */
  title?: string
  /**
   * The test ID used to locate this component in tests.
   */
  testID?: string
}

/**
 * A button.
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      icon,
      iconVariant,
      iconClassName,
      iconAlignment = 'left',
      title,
      testID,
      asChild = false,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    const renderIcon = (shouldRender?: boolean) =>
      shouldRender && icon
        ? React.createElement(icon, {
            className: cn('h-4 w-4', iconClassName),
            'aria-hidden': 'true',
            'aria-label': icon.name,
            variant: iconVariant
          })
        : null

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        data-testid={testID}
        title={title}
        {...props}
      >
        {renderIcon(iconAlignment === 'left')}
        {children}
        {renderIcon(iconAlignment === 'right')}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
