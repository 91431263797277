'use client'

import { createContext, useContext, useEffect, useRef } from 'react'

import { useUser } from '@auth0/nextjs-auth0/client'
import { useTracking } from 'react-tracking'

/* eslint-disable @typescript-eslint/no-explicit-any */
export type SystemLogContextType = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  trackUserEvent: (data: Partial<UserEvent>) => void
}

interface UserEvent {
  appName: string
  event: string
  /**
   * The auth0 user id
   */
  userId: string
  /**
   * The user id from our database
   */
  systemUserId: string
  /**
   * The email of the user
   */
  userEmail: string
  /**
   * The page of which this event was triggered
   */
  page: string
  /**
   * target of the event. i.e: button, form, etc...
   */
  target: string
  /**
   * any additional fields to be logged
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionals?: Record<string, any>
}

const SystemLogContext = createContext<SystemLogContextType>({
  trackUserEvent: () => null
})

export const SystemLogProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser()
  const userIdRef = useRef<string>('')
  const userEmailRef = useRef<string>('')
  const systemUserIdRef = useRef<string>('')

  useEffect(() => {
    if (user) {
      userIdRef.current = user.sub || ''
      userEmailRef.current = user.email || ''
      systemUserIdRef.current = (user['https://patientloop.com/lmd-user-id'] as string) || ''
    }
  }, [user])

  const { trackEvent } = useTracking(
    {},
    {
      dispatch: data => {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        const submitData = {
          appName: 'ui-roi',
          userId: userIdRef.current,
          userEmail: userEmailRef.current,
          systemUserId: systemUserIdRef.current,
          ...data
        } as UserEvent
        {
          fetch('/api/logs', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(submitData)
          })
        }
      }
    }
  )

  const trackUserEvent = (data: Partial<UserEvent>) => {
    trackEvent(data)
  }

  return <SystemLogContext.Provider value={{ trackUserEvent }}>{children}</SystemLogContext.Provider>
}

export function useSystemLog() {
  const context = useContext(SystemLogContext)
  if (!context) throw new Error('useSystemLog must be used within a Provider')
  return context
}
