'use client'

import Link from 'next/link'
import { useRouter } from 'next/navigation'

import { useSystemLog } from '@/contexts/systemLogContext'

type TrackableLinkProps = {
  href: string
  eventName: string
  eventTarget: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventAdditional?: Record<string, any>
  className?: string
  children: React.ReactNode
  dataTestId?: string
  systemUserId?: string
}

export const TrackableLink = ({
  href,
  eventName,
  eventTarget,
  eventAdditional,
  className,
  children,
  dataTestId,
  systemUserId
}: TrackableLinkProps) => {
  const { trackUserEvent } = useSystemLog()
  const { push } = useRouter()

  function handleClick(event: React.MouseEvent): void {
    event.preventDefault()

    trackUserEvent({
      event: eventName,
      target: eventTarget,
      additionals: eventAdditional,
      systemUserId
    })

    push(href)
  }

  return (
    <Link data-testid={dataTestId} href={href} className={className} onClick={handleClick}>
      {children}
    </Link>
  )
}
