'use client'
import { useEffect, useState } from 'react'

import Image from 'next/image'

import { TrackableLink } from '@/clientComponents/core/analytics/TrackableLink'
import { buttonVariants } from '@/clientComponents/ui/Button/Button'
import { cn } from '@/libs/utils'

export const LeadManagement = () => {
  const getStartedText = 'Get Started'
  const sectionHeader = 'Optimize Lead Management For Your Dental Practice'
  const imageSources = [
    '/new-patients.svg',
    '/lead-statuses.svg',
    '/patient-reviews.svg',
    '/scheduled-appointments.svg'
  ]

  const [animate, setAnimate] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
    setAnimate(true)

    const timer = setTimeout(() => {
      setAnimate(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="flex flex-col items-center bg-customGray-50 overflow-hidden">
      <h2 className="py-16 text-primaryExtra-900 font-bold text-center leading-normal text-6xl lg:px-64 md:px-32 max-md:px-8 max-md:py-16 max-md:text-3xl">
        {sectionHeader}
      </h2>
      <TrackableLink
        eventName="get-started-hero"
        eventTarget="landing"
        href="/api/auth/signup"
        className={cn(
          buttonVariants({ size: 'badge', variant: 'ghost' }),
          'bg-primary text-white hover:bg-primary-600 hover:text-white px-16 py-6'
        )}
      >
        {getStartedText}
      </TrackableLink>
      <div className="grid grid-cols-2 mt-16 min-h-[20rem] min-w-[30rem]">
        {loaded &&
          imageSources.map((src, index) => (
            <Image
              key={src}
              className={`${animate ? 'fadeInMoveUp' : ''} ${index % 2 === 0 ? 'mt-8' : ''} hoverUp`}
              src={src}
              width={500}
              height={500}
              objectPosition="right"
              objectFit="cover"
              alt=""
            />
          ))}
      </div>
    </div>
  )
}
